/**
  * headerFixed
  * mobileNav
  * eventLoad
  * alertBox
  * tabs
  * tabs2
  * goTop
  * AOS
  * flatAccordion
  * flatAccordions2
  * popupVideo
  * dropdown
  * no_link
  * flatCounter
  * ButtonSlide
  * parallax
  * loadmore
  * Preloader
*/

; (function ($) {
    "use strict";

    var isMobile = {
        Android: function () {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
          return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
          );
        },
      };

    var themesflatTheme = {

        // Main init function
        init: function () {
            this.config();
            this.events();
        },

        // Define vars for caching
        config: function () {
            this.config = {
                $window: $(window),
                $document: $(document),
            };
        },

        // Events
        events: function () {
            var self = this;

            // Run on document ready
            self.config.$document.on('ready', function () {


                // Retina Logos
                self.retinaLogo();


            });

            // Run on Window Load
            self.config.$window.on('load', function () {

            });
        },


        // Retina Logos
        retinaLogo: function () {
            var retina = window.devicePixelRatio > 1 ? true : false;
            var $logo = $('#site-logo img');
            var $logo2 = $('#logo-footer img');
            var $logo_retina = $logo.data('retina');

            if (retina && $logo_retina) {
                $logo.attr({
                    src: $logo.data('retina'),
                    width: $logo.data('width'),
                    height: $logo.data('height')
                });
            }
            if (retina && $logo_retina) {
                $logo2.attr({
                    src: $logo.data('retina'),
                    width: $logo.data('width'),
                    height: $logo.data('height')
                });
            }
            },
    }; // end themesflatTheme

    // Start things up
    themesflatTheme.init();

    // Header Fixed
    // var headerFixed = function () {
    //   console.log("Header fixed function...");
    //     if ($('body').hasClass('header-fixed')) {
    //       var nav = $('#header_main');
    //       console.log("Header fixed BODY NAV: ", nav);
    //         if (nav.length) {
    //             console.log("NAV LENGTH...");
    //             var offsetTop = nav.offset().top,
    //             injectSpace = $('<div />', {
    //             }).insertAfter(nav);
    //             $(window).on('load scroll', function () {
    //                 console.log("ON LOAD SCROLL: ", $(window).scrollTop());
    //                 if ($(window).scrollTop() > 200) {
    //                     nav.addClass('is-fixed');
    //                     injectSpace.show();
    //                 } else {
    //                     nav.removeClass('is-fixed');
    //                     injectSpace.hide();
    //                 }

    //                 if ($(window).scrollTop() > 300) {
    //                     nav.addClass('is-small');
    //                 } else {
    //                     nav.removeClass('is-small');
    //                 }
    //             })
    //         }
    //     }
    // };

    var ajaxSubscribe = {
        obj: {
            subscribeEmail: $('#subscribe-email'),
            subscribeButton: $('#subscribe-button'),
            subscribeMsg: $('#subscribe-msg'),
            subscribeContent: $("#subscribe-content"),
            dataMailchimp: $('#subscribe-form').attr('data-mailchimp'),
            success_message: '<div class="notification_ok">Thank you for joining our mailing list! Please check your email for a confirmation link.</div>',
            failure_message: '<div class="notification_error">Error! <strong>There was a problem processing your submission.</strong></div>',
            noticeError: '<div class="notification_error">{msg}</div>',
            noticeInfo: '<div class="notification_error">{msg}</div>',
            basicAction: 'mail/subscribe.php',
            mailChimpAction: 'mail/subscribe-mailchimp.php'
        },

        eventLoad: function () {
            var objUse = ajaxSubscribe.obj;

            $(objUse.subscribeButton).on('click', function () {
                if (window.ajaxCalling) return;
                var isMailchimp = objUse.dataMailchimp === 'true';

                if (isMailchimp) {
                    ajaxSubscribe.ajaxCall(objUse.mailChimpAction);
                } else {
                    ajaxSubscribe.ajaxCall(objUse.basicAction);
                }
            });
        },

        ajaxCall: function (action) {
            window.ajaxCalling = true;
            var objUse = ajaxSubscribe.obj;
            var messageDiv = objUse.subscribeMsg.html('').hide();
            $.ajax({
                url: action,
                type: 'POST',
                dataType: 'json',
                data: {
                    subscribeEmail: objUse.subscribeEmail.val()
                },
                success: function (responseData, textStatus, jqXHR) {
                    if (responseData.status) {
                        objUse.subscribeContent.fadeOut(500, function () {
                            messageDiv.html(objUse.success_message).fadeIn(500);
                        });
                    } else {
                        switch (responseData.msg) {
                            case "email-required":
                                messageDiv.html(objUse.noticeError.replace('{msg}', 'Error! <strong>Email is required.</strong>'));
                                break;
                            case "email-err":
                                messageDiv.html(objUse.noticeError.replace('{msg}', 'Error! <strong>Email invalid.</strong>'));
                                break;
                            case "duplicate":
                                messageDiv.html(objUse.noticeError.replace('{msg}', 'Error! <strong>Email is duplicate.</strong>'));
                                break;
                            case "filewrite":
                                messageDiv.html(objUse.noticeInfo.replace('{msg}', 'Error! <strong>Mail list file is open.</strong>'));
                                break;
                            case "undefined":
                                messageDiv.html(objUse.noticeInfo.replace('{msg}', 'Error! <strong>undefined error.</strong>'));
                                break;
                            case "api-error":
                                objUse.subscribeContent.fadeOut(500, function () {
                                    messageDiv.html(objUse.failure_message);
                                });
                        }
                        messageDiv.fadeIn(500);
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    alert('Connection error');
                },
                complete: function (data) {
                    window.ajaxCalling = false;
                }
            });
        }
    };

    var alertBox = function () {
        $(document).on('click', '.close', function (e) {
            $(this).closest('.flat-alert').remove();
            e.preventDefault();
        })

    };

    var flatAccordion = function() {
        var args = {duration: 600};
        $('.flat-toggle .toggle-title.active').siblings('.toggle-content').show();

        $(document).on('click', '.flat-toggle.enable .toggle-title', function() {
            $(this).closest('.flat-toggle').find('.toggle-content').slideToggle(args);
            $(this).toggleClass('active');
        }); // toggle

        $(document).on('click', '.flat-accordion .toggle-title', function () {
            $('.flat-accordion .flat-toggle').removeClass('active');
            $(this).closest('.flat-toggle').toggleClass('active');

            if( !$(this).is('.active') ) {
                $(this).closest('.flat-accordion').find('.toggle-title.active').toggleClass('active').next().slideToggle(args);
                $(this).toggleClass('active');
                $(this).next().slideToggle(args);
            } else {
                $(this).toggleClass('active');
                $(this).next().slideToggle(args);
                $('.flat-accordion .flat-toggle').removeClass('active');
            }
        }); // accordion
    };

    var tabs = function(){
        $('.flat-tabs').each(function(){
            $(this).find('.content-tab').children().hide();
            $(this).find('.content-tab').children().first().show();
            $(this).find('.menu-tab').children('li').on('click',function(){
                var liActive = $(this).index();
                var contentActive=$(this).siblings().removeClass('active').parents('.flat-tabs').find('.content-tab').children().eq(liActive);
                contentActive.addClass('active').fadeIn("slow");
                contentActive.siblings().removeClass('active');
                $(this).addClass('active').parents('.flat-tabs').find('.content-tab').children().eq(liActive).siblings().hide();
            });
        });
    };

    var tabs2 = function(){
        $('.flat-tabs-style2').each(function(){
            $(this).find('.content-tab').children().hide();
            $(this).find('.content-tab').find('.content-inner.active').show();
            $(this).find('.menu-tab').children('li').on('click',function(){
                var liActive = $(this).index();
                console.log(liActive);
                var contentActive=$(this).siblings().removeClass('active').parents('.flat-tabs-style2').find('.content-tab').children().eq(liActive);
                contentActive.toggleClass('active').fadeIn("slow");
                contentActive.siblings().removeClass('active');
                $(this).addClass('active').parents('.flat-tabs-style2').find('.content-tab').children().eq(liActive).siblings().hide();
            });
            $(this).find('.content-tab').find('.content-inner').find('.btn-delete').on('click',function(){
                $('.content-tab').find('.content-inner').hide();
            });
        });
    };

    // var popupVideo = function () {
    //     if ($().magnificPopup) {
    //       $(".popup-youtube").magnificPopup({
    //         type: "iframe",
    //         mainClass: "mfp-fade",
    //         removalDelay: 160,
    //         preloader: false,
    //         fixedContentPos: false,
    //       });
    //     }
    //   };
      var dropdown = function(id){
        var obj = $(id+'.dropdown');
        var btn = obj.find('.btn-selector');
        var dd = obj.find('ul');
        var opt = dd.find('li');
            opt.on("click", function() {
                // dd.hide();
                var txt = $(this).text();
                opt.removeClass("active");
                $(this).toggleClass("active");
                btn.text(txt);
            });
    };
    var no_link = function(){
        $('a.nolink').on('click', function(e){
          e.preventDefault();
      });
    }


    var flatCounter = function () {
        if ($(document.body).hasClass("counter-scroll")) {
          var a = 0;
          $(window).scroll(function () {
            var oTop = $(".box").offset().top - window.innerHeight;
            if (a == 0 && $(window).scrollTop() > oTop) {
              if ($().countTo) {
                $(".box")
                  .find(".number")
                  .each(function () {
                    var to = $(this).data("to"),
                      speed = $(this).data("speed");

                    $(this).countTo({
                      to: to,
                      speed: speed,
                    });
                  });
              }
              a = 1;
            }
          });
        }
    };

        var loadmore = function () {
        $(".fl-item").slice(0, 16).show();

        $(".loadmore").on("click", function(e){
          e.preventDefault();

          $(".fl-item:hidden").slice(0, 8).slideDown();
          if($(".fl-item:hidden").length == 0) {
            $(".loadmore").hide();
          }
        });
    };

    var ButtonSlide = function () {
        $('.btn-next-team,.btn-next').on('click', function () {
            $('.swiper-button-next').click();
        });
        $('.btn-prev-team,.btn-prev').on('click', function () {
            $('.swiper-button-prev').click();
        });
    };

    var parallax = function () {
        if ($().parallax && isMobile.any() == null) {
          $(".parallax").parallax("50%", 0.2);
        }
      };

      var flatAccordions2 = function() {
        var args = {easing:'easeOutExpo', duration:400};
        $('.widget.active').find('.content-widget').show();
        $('.widget-title').on('click', function () {
            if ( !$(this).parent().is('.active') ) {
                $(this).parent().toggleClass('active')
                    .children('.content-widget').slideToggle(args)
                    .children('.content-widget').slideToggle(args);
                    this.addClass('show');
            } else {
                $(this).parent().toggleClass('active');
                $(this).next().slideToggle(args);
            }
        });
    };

    var Preloader = function () {
        setTimeout(function () {
        $(".preload").fadeOut("slow", function () {
            $(this).remove();
        });
        }, 800);
    };

    // Dom Ready
    $(function () {
        // headerFixed();
        ajaxSubscribe.eventLoad();
        alertBox();
        tabs();
        tabs2();
        AOS.init();
        flatAccordion();
        flatAccordions2();
        // popupVideo();
        dropdown('#artworks');
        dropdown('#category');
        no_link();
        flatCounter();
        // ButtonSlide();
        $(window).on("load resize", function () {
            parallax();
        });
        loadmore();
        Preloader();
    });

})(jQuery);
